import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import ProductService from "../services/ProductService";
import { useParams } from "react-router-dom";
import Select from "react-select"
import { getUserByEmail } from "../services/UserService";
import OrderlineService from "../services/OrderlineService";

const selectOptions = [
    { value: 1, label: 1 },
    { value: 2, label: 2 },
    { value: 3, label: 3 },
    { value: 4, label: 4 },
    { value: 5, label: 5 },
];

export const Product = () => {

    const [product, setProducts] = useState(null);
    const [amount, setAmount] = useState(1);
    const { user } = useAuth0();
    const params = useParams();

    useEffect(() => {
        const getProductAsync = async () => {
            const response = await ProductService.getProductById(params.id);
            setProducts(response.data);
        }
        getProductAsync();
    }, []);

    const addToCart = async () => {
        const response = await getUserByEmail(user.email);
        console.log(response.data)
        const orderline = {
            totalPrice: amount.value * product.price,
            productId: product.id,
            userId: response.data.id,
            amount: amount.value
        }
        console.log(orderline);
        const orderlineResponse = await OrderlineService.createOrderline(orderline);
    }

    const renderProduct = () => {
        if (product) {
            return (
                <div className="product-page-render">
                    <div className="product-page-item">
                        <div className="img">
                            <img src={`${product.thumbnail}`} alt={`product-${params.id}`} />
                        </div>
                        <div className="info">
                            <div><span style={{ fontWeight: "bold" }}>Product id</span>: {product.id}</div>
                            <h2>{product.title}</h2>
                            <div style={{ marginBottom: "10px" }}>{product.description}</div>
                            <div style={{ fontWeight: "bold" }}>Cena {product.price} CZK</div>
                            <div>{product.category}</div>
                            <div>{product.producer}</div>
                        </div>
                    </div>
                    <div className="product-page-select">
                        <div style={{ display: "flex", flexDirection: "column", width: "30%" }}>
                            <button style={{ margin: "1rem 0" }} onClick={() => addToCart()}>Add to Cart</button>
                            <Select
                                isClearable={false}
                                className="react-select"
                                classNamePrefix="select"
                                options={selectOptions}
                                onChange={(amount) => setAmount(amount)}
                            />
                        </div>
                    </div>
                </div>
            )
        }
    }

    return (
        <div className="product-page-cont">
            {renderProduct()}
        </div>
    )

}