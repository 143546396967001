import React from "react"
import { useAuth0 } from "@auth0/auth0-react";

const RegisterButton = () => {
    const { loginWithRedirect, isAuthenticated, getAccessTokenSilently } = useAuth0();
    // const accessToken = getAccessTokenSilently();
    const handleSignUp = async () => {
        await loginWithRedirect({
            // prompt: "login",
            // screen_hint: "signup",
            appState: {
                returnTo: "/signup",
            },
            authorizationParams: {
                screen_hint: "signup",
            },
        });
    };

    return (
        !isAuthenticated && (
            <button onClick={() => handleSignUp()}>
                Sign up
            </button>
        )
    );
}

export default RegisterButton;