import axios from "axios";
import { venomProperties } from "../properties"

const ORDER_URL = venomProperties.orderingUrl;

export const createOrder = async (order) => {
    try {
        const response = await axios.post(`${ORDER_URL}`, order)
        console.log("The order has been created", response.data)
    } catch (error) {
        console.log(error.message)
    }
}