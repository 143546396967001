import { useNavigate } from "react-router-dom";


const CartButton = () => {
    const navigate = useNavigate();
    return (
        <img src={require("../assets/cart_logo.png")} alt="cart-logo"
            className="cart_button"
            onClick={() => navigate("/cart")}
        ></img>
    );
}

export default CartButton;