import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import OrderlineService from "../services/OrderlineService";
import { editUser, getUserById } from "../services/UserService";
import GlobalState from "../common/GlobalState";
import { createOrder } from "../services/OrderService";

const UserOrder = () => {
    const [user, setUser] = useState({
        id: "",
        name: "",
        email: "",
        city: "",
        address: ""
    });

    const [orderlines, setOrderlines] = useState([]);
    const [message, setMessage] = useState("");
    const params = useParams();

    const makeOrder = async () => {
        const totalPrice = orderlines.reduce((acc, item) => {
            return acc + item.totalPrice;
        }, 0)

        const newOrder = {
            userId: user.id,
            address: user.address,
            totalPrice,
            orderlines: orderlines
        }

        console.log(newOrder)

        await createOrder(newOrder);
    };

    const getUserByIdAsync = async () => {
        const response = await getUserById(params.userId);
        setUser(response.data);
    }

    const getOrderlinesByUserIdAsync = async () => {
        const response = await OrderlineService.getOrderlinesByUserId(params.userId);
        setOrderlines(response.data);
    }

    useEffect(() => {
        getUserByIdAsync();
        getOrderlinesByUserIdAsync();
    }, []);

    const submit = async event => {
        event.preventDefault();
        await editUser(user);
        setMessage("Delivery data has been updated");
        setTimeout(() => {
            setMessage("");
        }, 2000);
    }

    const setInputField = (event) => {
        const { name, value } = event.target;
        setUser((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }

    const renderOrderlines = () => {
        return GlobalState.orderlines.map((o, i) => {
            return (
                <div key={i}>
                    <div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <div style={{ marginRight: "1rem" }}>{i + 1}</div>
                            <div>
                                <div style={{ fontWeight: "bold" }}>{o.title}</div>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: "flex", flexFlow: "column", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <p style={{ paddingRight: "6rem" }}>Amount</p>
                            <p style={{ marginRight: "3rem" }}>Total price</p>
                        </div>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

                                <div style={{ marginRight: "7rem" }}>{o.amount}</div>
                                <div>{o.totalPrice} CZK</div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        });
    }

    

    return (
        <div>
            <h1>Delivery data</h1>
            <form style={{ display: "flex", flexDirection: "column" }} onSubmit={submit}>
                <label>
                    Name:
                    <input type="text" className="user-input" value={user.name} disabled />
                </label>
                <label>
                    Email:
                    <input type="text" className="user-input" value={user.email} disabled />
                </label>
                <label>
                    City:
                    <input type="text" className="user-input"
                        name="city" value={user.city}
                        onChange={setInputField}
                    />
                </label>
                <label>
                    Address:
                    <input type="text" style={{ marginLeft: "1rem" }}
                        name="address" value={user.address}
                        onChange={setInputField}
                    />
                </label>
                <input type="submit" value="Save" className="user-input-button" />
                <div style={{ marginTop: "1rem", marginLeft: "1rem", fontWeight: "bold" }}>{message}</div>
            </form>
            <h4 style={{ marginTop: "2rem" }}>Cart items</h4>
            <div>
                {renderOrderlines()}
                <div className="order-summary">
                    Total price: {orderlines.reduce((acc, item) => {
                        return acc + item.totalPrice;
                    }, 0)} CZK<br />
                </div>
            </div>
            <button className="user-input-button" style={{ martinTop: "2rem", cursor: "pointer" }}
                onClick={() => makeOrder()}>Place order
            </button>
        </div>
    )
}

export default UserOrder;