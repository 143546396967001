import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { saveUser } from "../services/UserService";
import { useNavigate } from 'react-router-dom'


const Signup = () => {
    const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
    // const { getAccessTokenSilently } = useAuth0();
    const navigate = useNavigate();

    useEffect(() => {
        const saveUserExtended = async (user) => {
            const newUser = {
                email: user.email,
                picture: user.picture,
                name: user.name
            }
            console.log(newUser);
            // const accessToken = await getAccessTokenSilently();
            // saveUser(accessToken, newUser);
            saveUser(newUser);
        }
        if (user) {
            console.log("User is not null")
            saveUserExtended(user);
        }
    }, [user /*getAccessTokenSilently*/])

    return (
        isAuthenticated && (
            <div>
                <img src={user.picture} alt={user.name} />
                <h2>{user.name}</h2>
                <p>{user.email}</p>
                <button onClick={() => navigate("/profile")}>
                    Profile
                </button>
            </div>
        )
    );

}

export default Signup;