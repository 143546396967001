import './App.css';
import { BrowserRouter, Routes, Route, Router } from 'react-router-dom';
import NavigationBar from './components/NavigationBar';
import Home from './pages/Home';
import Profile from './pages/Profile';
import { Product } from './pages/Product';
import Signup from './pages/Signup';
import Cart from './pages/Cart';
import { useAuth0 } from '@auth0/auth0-react';
import UserOrder from './pages/UserOrder';

function App() {
  const {isLoading, error} = useAuth0();

  return (
    <div className='container'>
      <NavigationBar/>
      {error && <p>Authentication Error</p>}
      {!error && isLoading && <p>Loading...</p>}
          <Routes>
            <Route path="/" element={<Home />}/>
            <Route path="/signup" element= {<Signup/>}/>
            <Route path="/product/:id" element={<Product />} />
            <Route path="/profile" element={<Profile/>}/>
            <Route path="/cart" element={<Cart/>}/>
            <Route path="/orders/:userId" element={<UserOrder/>}/>
          </Routes>
    </div>
  );
}

export default App;