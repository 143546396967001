import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import OrderlineService from "../services/OrderlineService";
import { useNavigate } from "react-router-dom";
import { getUserByEmail } from "../services/UserService";
import GlobalState from "../common/GlobalState";


const Cart = () => {
    const { user } = useAuth0();
    const [orderlines, setOrderlines] = useState([]);
    const [userId, setUserId] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        const getOrderlinesAsync = async () => {
            const response = await getUserByEmail(user.email);
            setUserId(response.data.id);
            const orderlineResponse = await OrderlineService.getOrderlinesByUserId(response.data.id);
            setOrderlines(orderlineResponse.data);
        }
        if (user) {
            getOrderlinesAsync();
        }
    }, [orderlines, user]);

    const incrementOrderlineAmount = async (id, index) => {
        orderlines[index].amount += 1;
        setOrderlines(orderlines);
        await OrderlineService.editOrderlineAmountById(id, orderlines[index].amount);
    }

    const decrementOrderlineAmount = async (id, index) => {
        if (orderlines[index] - 1 <= 0) {
            orderlines.splice(index, 1);
        } else {
            orderlines[index].amount -= 1;
            setOrderlines(orderlines);
            await OrderlineService.editOrderlineAmountById(id, orderlines[index].amount);
            // const price = orderlines[index].totalPrice / orderlines[index].amount;
            // orderlines[index] -= price;
        }
    }

    const deleteOrderlineFromCart = async (id, index) => {
        orderlines.splice(index, 1);
        setOrderlines(orderlines);
        console.log(id)
        await OrderlineService.removeOrderlineById(id);
    }

    const renderOrderlines = () => {
        return orderlines.map((o, i) =>
            <div key={i} className="orderline">
                <div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ marginRight: "1rem" }}>{i + 1}</div>
                        <div>
                            <div style={{ fontWeight: "bold" }}>{o.title}</div>
                            <img src={`${o.thumbnail}`} alt={`orderline-${i}`} style={{ width: "10vw" }} />
                        </div>
                    </div>
                </div>
                <div style={{ display: "flex", flexFlow: "column", justifyContent: "space-between" }}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <p style={{ paddingRight: "6rem" }}>Amount</p>
                        <p style={{ marginRight: "3rem" }}>Total price</p>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <div style={{ display: "flex", flexFlow: "column", marginRight: "2rem" }}>
                                <button
                                    style={{ marginBottom: "1rem", cursor: "pointer" }}
                                    onClick={() => incrementOrderlineAmount(o.id, i)}>+</button>
                                <button
                                    style={{ cursor: "pointer" }}
                                    onClick={() => decrementOrderlineAmount(o.id, i)}>-</button>
                            </div>
                            <div style={{ marginRight: "7rem" }}>{o.amount}</div>
                            <div>{o.totalPrice} CZK</div>
                        </div>
                        <div style={{ alignItems: "center", justifyContent: "center" }}>

                            <img src={require("../assets/close.png")} alt="close cross"
                                style={{ width: "2vw", marginLeft: "1rem", cursor: "pointer" }}
                                onClick={() => deleteOrderlineFromCart(o.id, i)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const navigateToPageOrder = () => {
        GlobalState.orderlines = orderlines;
        navigate(`/orders/${userId}`);
    }

    return (
        <div>
            {renderOrderlines()}
            <div className="order-summary">
                Total price: {orderlines.reduce((acc, item) => {
                    return acc + item.totalPrice;
                }, 0)} CZK
                <button onClick={() => navigateToPageOrder()}>Continue in order</button>
            </div>
        </div>
    );
}

export default Cart;