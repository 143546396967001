// export const venomProperties = {
//     productUrl: "http://localhost:8080/products",
//     orderlineUrl: "http://localhost:8080/orderlines",
//     userUrl: "http://localhost:8080/users",
//     orderingUlr: "http://localhost:8080/orders",
//     domain: "dev-hqypol5xft2pax1b.us.auth0.com",
//     clientId: "KCldlNZAwmMB9IRPI27FAq4AdgFMVnzy",
//     redirectUri: "http://localhost:3000",
// }

export const venomProperties = {
    productUrl: "https://embarrassed-stamp-production.up.railway.app/products",
    orderlineUrl: "https://embarrassed-stamp-production.up.railway.app/orderlines",
    userUrl: "https://embarrassed-stamp-production.up.railway.app/users",
    orderingUrl: "https://embarrassed-stamp-production.up.railway.app/orders",
    domain: "dev-hqypol5xft2pax1b.us.auth0.com",
    clientId: "zKIiLq1dDX9u7s2SAISLLbFjMoFXl9rp",
    redirectUri: "https://www.martinvedra.cz",
}