import axios from "axios";
import { venomProperties } from "../properties";

const PRODUCT_API_BASE_URL = venomProperties.productUrl;

class ProductService {
    async getProducts() {
        try {
            return axios.get(`${PRODUCT_API_BASE_URL}`);
        } catch (error) {
            console.error("Getting products error");
            return null;
        }
    }

    async getProductById(id) {
        try {
            return axios.get(`${PRODUCT_API_BASE_URL}/${id}`);
        } catch (error) {
            console.error("Getting product ID: " + `${id}` + " error");
        }
    }
}

export default new ProductService();