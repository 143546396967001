import { useEffect } from "react";
import { saveUser } from "../services/UserService";
import { useAuth0 } from "@auth0/auth0-react";


const StoreUserButton = () => {
    const { user } = useAuth0();

    const addDefaultUser = async (user) => {
        const newUser = {
            email: user.email,
            picture: user.picture,
            name: user.name
        }
        saveUser(newUser);
    }

    return (
        <button onClick={() => addDefaultUser(user)}>
            Save user
        </button>
    )
}

export default StoreUserButton;