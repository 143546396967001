import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation, useNavigate } from "react-router-dom";
import ProfileButton from "./ProfileButton";
import RegisterButton from "./RegisterButton";
import LogoutButton from "./LogoutButton";
import LoginButton from "./LoginButton";
import CartButton from "./CartButton";
import StoreUserButton from "./StoreUserButton";


const NavigationBar = () => {
    const { isAuthenticated, user } = useAuth0();
    const location = useLocation();

    const renderCartButton = () => {
        if (isAuthenticated) {
            return <CartButton/>;
        }
    }

    const renderProfileButton = () => {
        if (isAuthenticated && location.pathname !== "/profile") {
            return <ProfileButton />;
        }
        return null;
    }

    const renderRegisterButton = () => {
        // if (isAuthenticated) {
        //     return null;
        // }
        return <RegisterButton />;
    }

    const renderButton = () => {
        // if (isAuthenticated) {
        //     return <LogoutButton />;
        // }
        // return <LoginButton />;
        return (
            <><LoginButton /><LogoutButton /></>
        );
    }

    return (
        <nav className="navigation-bar">
            <div className="login-bar">
                <div>
                    <a href="/">My E-Shop</a>
                </div>
                <div>
                    <StoreUserButton/>
                    {renderCartButton()}
                    {renderProfileButton()}
                    {renderRegisterButton()}
                    {isAuthenticated && <p> {user.name} </p>}
                    {renderButton()}
                </div>
            </div>
            <div className="menu">
                <h3>Martin Vedra</h3>
            </div>
        </nav>
    )
}

export default NavigationBar;