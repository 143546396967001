import axios from "axios";
import { venomProperties } from "../properties";

const USER_API_BASE_URL = venomProperties.userUrl;

export const saveUser = async (user) => {
    try {
        const response = await axios.post(`${USER_API_BASE_URL}`, user,
            // {
                // headers: {
                    // 'Content-Type': 'application/json;charset=UTF-8',
                    // "Access-Control-Allow-Origin": "*",
                    // "Content-Type": "application/json",
                    // Authorization: `Bearer ${accessToken}`,
                // }
            // }
        )
        // console.log(response.data)
        console.log("The user has been created", response.data)
    } catch (error) {
        console.log("User already exists")
    }
}

export const editUser = async (user) => {
    try {
        const response = await axios.put(`${USER_API_BASE_URL}`, user);
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const getUserById = async (userId) => {
    try {
        const response = await axios.get(`${USER_API_BASE_URL}/id/${userId}`);
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const getUserByEmail = async (email) => {
    try {
        const response = await axios.get(`${USER_API_BASE_URL}/email/${email}`);
        // const response = await axios.get("http://localhost:8080/users/email/" + email);
        return response;
    } catch (error) {
        console.log(error);
    }
}




