import React from 'react';
import ReactDOM, { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@auth0/auth0-react';
import { venomProperties } from './properties';
import { BrowserRouter, redirect } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import Auth0ProviderWithNavigate from './auth0-provider-with-navigate.js';
import AuthProvider from './auth_provider';

const container = document.getElementById("root");
const root = createRoot(container);

// const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      {/* <Auth0ProviderWithNavigate>
        <App />
      </Auth0ProviderWithNavigate> */}
      {/* <Auth0Provider
        domain={venomProperties.domain}
        clientId={venomProperties.clientId}
        authorizationParams={{
          redirect_uri: venomProperties.signupUrl,
        }}
      >
        <App />
      </Auth0Provider> */}
      <AuthProvider>
        <App/>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
