import { Auth0Provider } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { venomProperties } from "./properties";



const AuthProvider = ({children}) => {
    const navigate = useNavigate();

    const onRedirectCallback = (appState) => {
        navigate(appState?.returnTo || window.location.pathname);
    };

    return (
        <Auth0Provider
            domain={venomProperties.domain}
            clientId={venomProperties.clientId}
            authorizationParams={{
                redirect_uri: venomProperties.redirectUri,
            }}
            onRedirectCallback={onRedirectCallback}
        >
            {children}
        </Auth0Provider>
    );

}

export default AuthProvider;