import axios from "axios";
import { venomProperties } from "../properties";

const ORDERLINE_URL = venomProperties.orderlineUrl;

class OrderlineService {
    async createOrderline(orderline) {
        const response = await axios.post(`${ORDERLINE_URL}`, orderline);
        return response;
    }

    async editOrderlineAmountById(orderlineId, amount) {
        return await axios.put(`${ORDERLINE_URL}?orderlineId=${orderlineId}&amount=${amount}`);
    }

    async getOrderlinesByUserId(userId) {
        const response = await axios.get(`${ORDERLINE_URL}?userId=${userId}`);
        return response;
    }

    async removeOrderlineById(orderlineId) {
        const response = await axios.delete(`${ORDERLINE_URL}?orderlineId=${orderlineId}`);
        return response;
    }
}

export default new OrderlineService ();