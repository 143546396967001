import React, { useEffect, useState } from "react";
import ProductService from "../services/ProductService";
import { useNavigate } from "react-router-dom";

const Home = () => {

    const [products, setProducts] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const getProductAsync = async () => {
            const response = await ProductService.getProducts();
            setProducts(response.data);
        }
        getProductAsync();
    }, []);

    const displayText = (product) => {
        if (product) {
            if (product.description.length > 200) {
                return product.description.substring(0, 150) + "...";
            }
            return product.description;
        }
    }

    const renderProducts = () => {
        return products.map((product, index) => {
            return (
                <div className="product-item" key={index}>
                    <div className="product-item-id">Id: {product.id}</div>
                    <div className="product-item-img">
                        <img src={product.thumbnail} alt={product.title}></img>
                    </div>
                    <div className="product-item-title">{product.title}</div>
                    <div className="desc">{displayText(product)}</div>
                    <div className="price">{product.price} Kč</div>
                    <div className="buy-button">
                        <button onClick={() => navigate(`/product/${product.id}`)}>Buy</button>
                    </div>
                </div>
            );
        });
    }

    return (
        <div className="home-products-container">
            <div className="products">
                {renderProducts()}
            </div>
        </div>
    );
}

export default Home;