import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const LoginButton = () => {
    const { loginWithRedirect, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const handleLogin = async () => {
        await loginWithRedirect({
            // prompt: "login",
            // screen_hint: "signup",
            appState: {
                returnTo: "/profile",
            },
        });
    };
    // const accessToken = getAccessTokenSilently();
    return (
        !isAuthenticated && (
            <button onClick={() => handleLogin()}>
                Log In
            </button>
        )
    );
};

export default LoginButton;